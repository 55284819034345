import Stack from "@mui/material/Stack";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import { CryiftIcon } from "../components/CryiftIcon";

const styles = (theme) => ({
  title: {
    fontWeight: "bold",
  },
  icon: {
    width: "8%",
    alignItems: "center",
  },
  typography: {
    width: "30%",
    fontSize: "22px",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
});

const ComingSoon = ({ classes }) => {
  return (
    <Stack spacing={2} alignItems="center">
      <br />
      <br />
      <CryiftIcon className={classes.icon} />
      <br />
      <h1 className={classes.title}>Coming Soon</h1>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Typography className={classes.typography}>
        Cryift is a new crypto platform purpose built for gifting crypto. It has
        never been easier to give somebody crypto, even if they don't have a
        wallet initially, you can even opt to let them receive it in the future.
      </Typography>
      <Typography className={classes.typography}>
        Remember when you gave your friend a giftcard and he never used it? With
        cryift cards you can opt to have your funds returned to you if they are
        never recieved.
      </Typography>
    </Stack>
  );
};

export default withStyles(styles)(ComingSoon);
