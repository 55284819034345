import * as React from 'react';
// import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import { CssBaseline, ThemeProvider } from '@mui/material';

import theme from './theme'

import ComingSoon from "./pages/ComingSoon"
import NavBar from './components/NavBar';


export default function App() {
  return  <ThemeProvider theme={theme}>
    <CssBaseline />
    <NavBar/>
    <ComingSoon/>
  </ThemeProvider>
}

//ReactDOM.render(<App />, document.querySelector('#app'));